import React, { useMemo } from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"

import cx from "classnames"
import SimpleP from "../../components/block-content/simpleP"
import YoutubeEmbed from "../../components/youtubeEmbed"
import TestimonialSlider from "./testimonialSlider"
import Figure from "../figure"

import styles from "./heroSection.module.scss"

const HeroSection = props => {
  const { headingSpace } = props

  // Memoize the YouTube embedId from the provided link
  const embedId = useMemo(() => {
    if (!props.link) return null
    const youtubeUrl = new URL(props.link)
    return youtubeUrl.searchParams.get("v")
  }, [props.link])

  // Determine if there's any content for heading, video, or image
  const isHeading =
    !!props.heading || !!props.tagline || embedId || !!props.image
  const isProducts = !!props.productsBanner?.products?.length

  const location = useLocation() // Get the current page's location

  // Check if the current page is NOT the homepage
  const isNotHomepage = location.pathname !== "/"

  const heroStyles = cx(styles.hero, styles.hero__testimonial, {
    [styles.hero__noHeading]: !isHeading,
  })

  return (
    <section
      className={heroStyles}
      style={isNotHomepage ? { paddingTop: "100px" } : undefined} // Conditionally apply paddingTop inline
    >
      <div className={styles.heroContent}>
        {isHeading && (
          <div
            style={
              headingSpace ? { "--heading-space": `${headingSpace}px` } : {}
            }
            className={styles.heroHeading}
          >
            <div className={styles.heroTitles}>
              <h1>{props.heading}</h1>
              {props.tagline && <SimpleP blocks={props.tagline} />}
            </div>

            {/* Check for videoFile first, then YouTube embed, then image */}
            {props.videoFile ? (
              <video
                controls
                src={props.videoFile.asset.url} // Assuming the video URL is in asset.url
                poster={props.videoFile.asset.poster} // Optional: poster image for video
                className={styles.videoEmbed} // Optional: custom styling
              >
                Your browser does not support the video tag.
              </video>
            ) : embedId ? (
              <YoutubeEmbed
                embedId={embedId}
                previewAsset={props.videoFile?.asset} // Optional previewAsset
              />
            ) : (
              <Figure {...props.image} />
            )}
          </div>
        )}

        {isProducts && (
          <div className={styles.heroProducts}>
            {props.productsBanner.products.map(product => (
              <Link
                key={product._id}
                to={`/products/${product.slug}/`}
                className={styles.heroProductItem}
                style={{ alignContent: "center" }}
              >
                <span>{product.title}</span>
              </Link>
            ))}
          </div>
        )}

        <TestimonialSlider {...props.testimonialSection} />
      </div>
    </section>
  )
}

export default HeroSection
