import React, { useState, useEffect, useRef } from "react"
import Slick from "react-slick"
import cx from "classnames"

import SimpleP from "../block-content/simpleP"
import CTAButton from "../ctaButton"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./testimonialSlider.scss"

// Arrow Components
const ArrowLeft = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props} className="sliderArrow prev" />
)
const ArrowRight = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props} className="sliderArrow next" />
)

// Main Component
const TestimonialSlider = ({
  className,
  testimonials = [],
  ctas,
  background,
  imageBackground,
  bottomIndent,
  showTestimonials = true,
}) => {
  const [mainSlider, setMainSlider] = useState(null)
  const [navSlider, setNavSlider] = useState(null)
  const [amountOfSlides, setAmountOfSlides] = useState(0)

  const navigationRef = useRef(null)
  const contentRef = useRef(null)
  const isSingle = amountOfSlides === 1
  const isImageBackground = background === "image"
  const hasSlideLogo = testimonials.some(slide => slide._rawLogo)

  useEffect(() => {
    setAmountOfSlides(testimonials.length >= 5 ? 5 : testimonials.length)
  }, [])

  useEffect(() => {
    // Workaround for having a carousel even when there are only 3 or 3 slides
    if (amountOfSlides > 2 && amountOfSlides < 5) {
      const width = amountOfSlides * 280 // width of one slide when variable width is true
      navigationRef.current.style.maxWidth = `${width}px`
    }
  }, [amountOfSlides])

  const settingsMain = {
    asNavFor: navSlider,
    ref: slider => setMainSlider(slider),
    arrows: false,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  // Workaround for having a carousel even when there are only 3 or 3 slides
  const settingsNavigationSetWidth = {
    asNavFor: mainSlider,
    ref: slider => setNavSlider(slider),
    centerMode: true,
    centerPadding: "0",
    infinite: true,
    slidesToShow: amountOfSlides,
    slidesToScroll: 1,
    focusOnSelect: true,
    variableWidth: true,
  }

  const settingsNavigationSetSlides = {
    asNavFor: mainSlider,
    ref: slider => setNavSlider(slider),
    centerMode: true,
    centerPadding: "0",
    infinite: true,
    slidesToShow: amountOfSlides,
    slidesToScroll: 1,
    focusOnSelect: true,
  }

  const settingsNavigation =
    amountOfSlides <= 2 || testimonials.length > 5
      ? settingsNavigationSetSlides
      : settingsNavigationSetWidth

  const testimonialSectionClass = cx("testimonial-section", {
    [className]: className,
    bottomIndent: !!bottomIndent,
  })

  const sliderInnerClass = cx("sliderInner", {
    [background]: background,
    [imageBackground]: !!imageBackground && isImageBackground,
    single: isSingle,
    multipleAndCtas: !isSingle && ctas,
    noSlideLogo: !hasSlideLogo,
  })

  const sliderNavClass = cx("sliderNav", {
    [background]: background,
    single: isSingle,
    singleAndCtas: isSingle && ctas,
    multipleAndCtas: !isSingle && ctas,
  })

  return (
    <section className={testimonialSectionClass} ref={contentRef}>
      {testimonials.length && (
        <>
          {showTestimonials && (
            <div className={sliderInnerClass}>
              <Slick {...settingsMain}>
                {testimonials.map(slide => (
                  <div className="slide" key={slide._id}>
                    <div className="slideInner">
                      <SimpleP blocks={slide.testimonial} />
                      {slide.name ? (
                        <p className="slideName">- {slide.name} </p>
                      ) : null}
                    </div>
                  </div>
                ))}
              </Slick>
            </div>
          )}
          {hasSlideLogo && (
            <div className={sliderNavClass} ref={navigationRef}>
              <Slick {...settingsNavigation}>
                {testimonials.map(slide => (
                  <div key={`nav_${slide.id}`}>
                    {slide._rawLogo && (
                      <img
                        style={
                          showTestimonials ? undefined : { filter: "none" }
                        }
                        src={slide._rawLogo.image.asset.url}
                        alt={slide._rawLogo.alt}
                      />
                    )}
                  </div>
                ))}
              </Slick>
            </div>
          )}
        </>
      )}
      {ctas && (
        <div className="sliderCta">
          {ctas.link.map(cta => (
            <CTAButton key={cta._key} blocks={cta} />
          ))}
        </div>
      )}
    </section>
  )
}

export default TestimonialSlider
