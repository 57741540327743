import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import Figure from "../figure"

import styles from "./statsSquaresSection.module.scss"

const StatsSquaresSection = ({ header, headerPosition, stats }) => {
  if (!stats?.length) return null
  const isAbove = headerPosition === "above"

  return (
    <section className={styles.container}>
      {header && isAbove && <h2 className={styles.header}>{header}</h2>}
      <div className={cx({ [styles.leftWrapper]: !isAbove })}>
        {header && !isAbove && <h2>{header}</h2>}
        <div className={styles.statsWrapper}>
          {stats.map(({ _key, icon, value, label }) => (
            <div key={_key} className={styles.statItem}>
              {icon?.image?.asset?.url && <Figure {...icon} />}
              <p className={styles.value}>{value}</p>
              <p>{label}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

StatsSquaresSection.propTypes = {
  header: PropTypes.string,
  headerPosition: PropTypes.oneOf(["above", "left"]),
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      _key: PropTypes.string.isRequired,
      icon: PropTypes.shape({
        image: PropTypes.shape({
          asset: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
        alt: PropTypes.string,
      }),
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
}

StatsSquaresSection.defaultProps = {
  header: "",
  headerPosition: "above",
  stats: [],
}

export default StatsSquaresSection
