import BaseBlockContent from "@sanity/block-content-to-react"
import React from "react"

import ImageSection from "./imageSection"
import TestimonialSlider from "./testimonialSlider"
import BannerSection from "./bannerSection"
import ProductsBanner from "./productsBanner"
import LatestSection from "./latestSection"
import LatestBlocksSection from "./latestBlocksSection"
import SolutionsSection from "./solutionsSection"
import PageLinkSection from "./pageLinkSection"
import TwoColCtaBanner from "./twoColCtaBanner"
import ThreeColumnSection from "./threeColumnSection"
import TeamSection from "./teamSection"
import PartnersSection from "./partnersSection"
import StepsSection from "./stepsSection"
import CheckList from "./checkList"
import FormSection from "../../modules/form"
import PhoneNumberList from "./phoneNumberList"
import SectionBuilder from "./sectionBuilder"
import BenefitsSection from "./benefitsSection"
import ClientSection from "./clientSection"
import VideoSection from "./videoSection"
import AnchorSection from "./anchorSection"
import HeroSection from "./heroSection"
import ProductItemsSection from "./productItemsSection"
import WhyPeopleFluentSection from "./whyPeopleFluentSection"
import FaqSection from "./faqSection"
import HtmlContentSection from "./HtmlContentSection"
import StatsSquaresSection from "./StatsSquaresSection"

import styles from "./htmlContentSection.module.scss"

export const serializers = {
  types: {
    imageSection(props) {
      return <ImageSection {...props.node} />
    },
    testimonialSection(props) {
      return <TestimonialSlider {...props.node} />
    },
    bannerSection(props) {
      return <BannerSection {...props.node} />
    },
    productsBanner(props) {
      return <ProductsBanner {...props.node} />
    },
    latestSection(props) {
      if (props.node.view === "blocks") {
        return <LatestBlocksSection {...props.node} />
      }
      return <LatestSection {...props.node} />
    },
    solutionsSection(props) {
      return <SolutionsSection {...props.node} />
    },
    pageLinkSection(props) {
      return <PageLinkSection {...props.node} />
    },
    twoColCtaBanner(props) {
      return <TwoColCtaBanner {...props.node} />
    },
    threeColumnSection(props) {
      return <ThreeColumnSection {...props.node} />
    },
    stepsSection(props) {
      return <StepsSection {...props.node} />
    },
    teamSection(props) {
      return <TeamSection {...props.node} />
    },
    partnersSection(props) {
      return <PartnersSection {...props.node} />
    },
    checkList(props) {
      return <CheckList {...props.node} />
    },
    formSection(props) {
      return (
        <FormSection
          title={props.node.formTitle}
          link={props.node.formLink}
          type={props.node.formPageType}
          subtitle={props.node.formSubtitle}
        />
      )
    },
    phoneNumberList(props) {
      return <PhoneNumberList {...props.node} />
    },
    benefitsSection(props) {
      return <BenefitsSection {...props.node} />
    },
    sectionBuilder(props) {
      return <SectionBuilder {...props.node} />
    },
    clientSection(props) {
      return <ClientSection {...props.node} />
    },
    videoSection(props) {
      return <VideoSection {...props.node} />
    },
    anchorSection(props) {
      return <AnchorSection {...props.node} />
    },
    heroSection(props) {
      return <HeroSection {...props.node} />
    },
    productItemsSection(props) {
      return <ProductItemsSection {...props.node} />
    },
    whyPeopleFluentSection(props) {
      return <WhyPeopleFluentSection {...props.node} />
    },
    faqSection(props) {
      return <FaqSection {...props.node} />
    },
    htmlContent(props) {
      return (
        <section id={`section-${props.node._key}`} className={styles.htmlContentSection}>
          <HtmlContentSection {...props.node} />
        </section>
      );
    },
    statsSquares(props) {
      return <StatsSquaresSection {...props.node} />
    },
  },
}

const PageBuilder = ({ blocks }) => {
  blocks?.forEach((block, index) => {
    if (block._type === "imageSection") {
      const prevBlock = blocks[index - 1]
      if (
        prevBlock !== undefined &&
        prevBlock._type === "imageSection" &&
        !prevBlock.reversed
      ) {
        block.reversed = true
      }
    }
  })
  return (
    <BaseBlockContent
      className="pageContent"
      blocks={blocks}
      serializers={serializers}
    />
  )
}

export default PageBuilder
