import React from "react"
import cx from "classnames"

import Button from "../button"
import Figure from "../figure"

import styles from "./whyPeopleFluentSection.module.scss"

const WhyPeopleFluentSection = ({
  title,
  description,
  achievements,
  mainCtas,
  subtitle,
  subCtas,
  firstIndent,
  secondIndent,
  image,
}) => {
  const isFirstMainPart = title || description || mainCtas?.link.length
  const isSecondMainPart = !!achievements?.length
  const isMainInfo = isFirstMainPart || isSecondMainPart
  const isTwoMainParts = isFirstMainPart && isSecondMainPart

  const whySectionMain = cx(styles.whySectionMain, {
    [styles.whySectionMain__indent]: !!firstIndent,
    [styles.whySectionMain__twoParts]: isTwoMainParts,
  })

  const whySectionSecondary = cx(styles.whySectionSecondary, {
    [styles.whySectionSecondary__indent]: !!secondIndent,
  })

  return (
    <section>
      {isMainInfo && (
        <div className={whySectionMain}>
          {isFirstMainPart && (
            <div className={styles.whySectionMainInfo}>
              {title && (
                <h1 className={styles.whySectionMainInfoTitle}>{title}</h1>
              )}
              {description && (
                <div className={styles.whySectionMainInfoDesc}>
                  {description}
                </div>
              )}
              {mainCtas?.link.map(link => (
                <Button
                  className={styles.whySectionMainInfoLink}
                  label={link.title}
                  key={link._key}
                  to={link.link}
                  type={link.type}
                />
              ))}
            </div>
          )}
          {isSecondMainPart && (
            <div className={styles.whySectionMainAchievements}>
              {achievements.map(achievement => (
                <div
                  className={styles.whySectionMainAchievementsItem}
                  key={achievement.id}
                >
                  <div className={styles.whySectionMainAchievementsItemValue}>
                    {achievement.value}
                    {achievement.prefix ? <sup>+</sup> : null}
                  </div>
                  <div className={styles.whySectionMainAchievementsItemTitle}>
                    {achievement.title}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      <div className={whySectionSecondary}>
        {subtitle && <h1 className={styles.whySectionSubtitle}>{subtitle}</h1>}
        {subCtas?.link?.map(link => (
          <Button
            className={styles.whySectionSecondaryLink}
            label={link.title}
            key={link._key}
            to={link.link}
            type={link.type}
          />
        ))}
        <div className={styles.whySectionMainInfoImage}>
          <Figure {...image} />
        </div>
      </div>
    </section>
  )
}

export default WhyPeopleFluentSection
