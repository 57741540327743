import React from "react"
import BaseBlockContent from "@sanity/block-content-to-react"
import Button from "../button"

const serializers = {
  types: {
    block(props) {
      return <p>{props.children}</p>
    },
  },
  marks: {
    link: ({ children, mark }) => {
      if (!mark?.link) return children

      return (
        <Button
          type={mark?.type ?? "ghostSecondary"}
          to={mark.link}
          label={children}
          target={mark.newTab}
          noFollow={mark.noFollow}
        />
      )
    },
  },
}

const SimpleP = ({ blocks }) => (
  <BaseBlockContent blocks={blocks} serializers={serializers} />
)

export default SimpleP
